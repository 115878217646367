import React, { useState, useRef, useEffect } from "react";
import ReactVivus from "react-vivus";
import logo from "../../static/logo.svg";
import { useViewportScroll, motion, useTransform, useMotionValue } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HaddadBooksLogo = (props) => {
  const [done1, setDone1] = useState(false);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  });

  return (
    <div data-theme="contact" className="relative grid grid-cols-2 w-full h-full svg-anim">
      <ReactVivus
        id="LogoSVG"
        option={{
          file: logo,
          animTimingFunction: "EASE_OUT",
          pathTimingFunction: "EASE_OUT",
          type: "scenario-sync",
          // start: "autostart",
          reverseStack: true,
          duration: 10,
        }}
        callback={() => setDone1(true)}
        className={`${done1 && "finished"} flex-1 left-0 relative w-screen h-full z-10`}
      />
      <div className="h-full w-full" />
    </div>
  );
};

export default HaddadBooksLogo;
