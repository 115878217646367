import ReactDOM from 'react-dom'
import React, { useRef, useState, Suspense } from 'react'
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import { Canvas, useFrame, useLoader} from 'react-three-fiber'

function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()

    const {book} = props;
  
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
  
    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => {
    //   mesh.current.rotation.x = mesh.current.rotation.y += 0.01
        mesh.current.rotation.y += 0.005
    })

    const texture_1 = useLoader(TextureLoader, book.front)
    const texture_2 = useLoader(TextureLoader, book.spine)
    const texture_3 = useLoader(TextureLoader, book.spine)
    const texture_4 = useLoader(TextureLoader, book.spine)
    const texture_5 = useLoader(TextureLoader, book.back)
    const texture_6 = useLoader(TextureLoader, book.front)
  
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={hovered ? [2.6, 2.6, 2.6] : [2.5, 2.5, 2.5]}
        // onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        >
        <boxBufferGeometry args={[1, 1.5, .05]} />
        <meshStandardMaterial map={texture_1} attachArray="material" />
        <meshStandardMaterial map={texture_2} attachArray="material" />
        <meshStandardMaterial map={texture_3} attachArray="material" />
        <meshStandardMaterial map={texture_4} attachArray="material" />
        <meshStandardMaterial map={texture_5} attachArray="material" />
        <meshStandardMaterial map={texture_6} attachArray="material" />
      </mesh>
    )
  }

const ThreeBook = props => {
    const {_rawFrontCover, _rawBackCover, _rawSpine, _rawSpreads, _rawDescription, title, purchaseUrls} = props.book ? props.book[0] : [];
    
    const book = {
        front: _rawFrontCover ? _rawFrontCover.asset.url : '',
        back: _rawBackCover ? _rawBackCover.asset.url : '',
        spine: _rawSpine ? _rawSpine.asset.url : '',
        spreads: _rawSpreads ?_rawSpreads : []
    }
    
    return (
        <Canvas className='h-48 w-48 -mb-6'>
            <ambientLight intensity={0.5} />
            {/* <spotLight position={[25, 25, 25]} angle={0.15} penumbra={1} /> */}
            <pointLight position={[-10, -10, -10]} />
            <Suspense fallback={null}>
                <Box book={book} position={[0, 0, 0]} />
            </Suspense>
        </Canvas>
    )
}

export default ThreeBook;