import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Contact from "./contact";
import "../styles/layout.css";
import InViewWrapper from "./in-view-wrapper";
import PortableText from "./portableText";
import Figure from "./Figure";
import ThreeBook from "./three-book";
import { HaddadBooksLogo, FifteenWord, PoemsWord, DownArrow, LeftArrow, RightArrow } from "./svg";
import FifteenPoems from "./fifteen-poems";
import InPraiseOfThought from "./in-praise-of-thought";
import AboutIdentity from "./about-identity";
import { useElementScroll } from "framer-motion";
import { useKeenSlider } from "keen-slider/react";
import { useInView, InView } from "react-intersection-observer";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { useSwipeable } from "react-swipeable";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, about, book }) => {
  const [theme, setTheme] = useState("home");
  // const ref = useRef();
  // const { scrollYProgress } = useElementScroll(ref)
  const [opacities, setOpacities] = React.useState([]);
  const timer = useRef();
  const [pause, setPause] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [newsletterOpen, setNewsletterOpen] = useState(false);
  const [stockistOpen, setStockistOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [formState, setFormState] = useState(null);
  const [booksTriggered, setBooksTriggered] = useState(false);

  //swipeable
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log('swipe left')
      if (slider && inView && timer.current) {
        slider.next();
        clearInterval(timer.current);
        console.log('slider next')
        setPause(true);
      }
    },
    onSwipedRight: () => {
      console.log('swipe right')
      if (slider && inView && timer.current) {
        console.log('slider prev')
        clearInterval(timer.current);
        slider.prev();
        setPause(true);
      }
    }
  });
  // setup ref for your usage
  const myRef = React.useRef();

  const refPassthrough = (el) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    myRef.current = el;
  };

  const { ref, inView, entry } = useInView({
    threshold: [0.1, 0.3],
  });

  const videoRef = useRef();

  const playVideo = () => {
    videoRef && videoRef.current && videoRef.current.play();
  };

  const [sliderRef, slider] = useKeenSlider({
    slides: book && book[0]._rawSpreads.length,
    loop: true,
    duration: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    move(s) {
      const new_opacities = s.details().positions.map((slide) => slide.portion);
      setOpacities(new_opacities);
    },
  });

  const navigate = (e) => {
    if (typeof window) {
      clearInterval(timer.current);
      if (e.clientX < window.innerWidth / 2) {
        slider.prev();
        setPause(true);
      } else {
        slider.next();
        setPause(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(emailInput);
    const result = await addToMailchimp(emailInput);
    setFormState(result);
  };

  const handleEmailChange = (event) => {
    console.log(event.target.value);
    setEmailInput(event.target.value);
  };

  const mouseMove = (e) => {
    if (typeof window) {
      if (e.clientX < window.innerWidth / 2) {
        // sliderRef.current.classList.add("left-cursor");
        // sliderRef.current.classList.remove("right-cursor");
      } else {
        // sliderRef.current.classList.add("right-cursor");
        // sliderRef.current.classList.remove("left-cursor");
      }
    }
  };

  // useEffect(() => {
  //   console.log(inView);
  //   timer.current = setInterval(() => {
  //     if (!pause && slider && inView) {
  //       slider.next();
  //     }
  //   }, 4000);
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }, [pause, slider, inView]);

  const prevSlide = () => {
    slider && slider.prev();
    setPause(true);
  }

  const nextSlide = () => {
    slider && slider.next();
    setPause(true);
  }

  // useEffect(() => {
  //   console.log(theme)
  //   if (theme === 'books') {
  //     setBooksTriggered(true);
  //   }
  // }, [theme]);

  useEffect(() => {
    if (typeof window) window.scrollTo(window.scrollX, window.scrollY + 1);
  }, []);

  useEffect(() => {
    console.log(theme);
    if (theme !== "books" && !pause) {
      slider && slider.moveToSlideRelative(0);
    }
  }, [theme]);

  return (
    <>
      <div data-modal-open={modalOpen} id="home" className="transition-colors duration-1000">
        <Header
          theme={theme}
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
        />

        {/* <div
          className={`${stockistOpen ? "-translate-y-1/2  top-1/2" : "translate-y-full top-full"
            } bg-white w-full transition-all duration-500 z-50 max-w-xs fixed left-1/2 transform -translate-x-1/2 `}
        >
          <button
            onClick={() => {
              setStockistOpen(false);
              setModalOpen(false);
            }}

            className="w-4 h-4 p-2 m-3 absolute right-0 top-0 hover:opacity-30"
          >
            <DownArrow />
          </button>
          <ul className="px-5 py-2 text-xs font-sans tracking-widest">
            <li>
              <h2 className=" mt-2">Distribution</h2>
              <br></br>
              <br></br>
            </li>
            <li className="border-b ">
              <a
                className="block py-2 hover:opacity-50"
                target="blank"
                href=""
                title="Barnes and Nobles"
              >
                Barnes &amp; Noble
              </a>
            </li>
            <li className="border-b ">
              <a
                className="block py-2 hover:opacity-50"
                target="blank"
                href=""
                title="Penguin Books"
              >
                Penguin Books
              </a>
            </li>
            <li className="border-b ">
              <a
                className="block py-2 hover:opacity-50"
                target="blank"
                href=""
                title="Three Lives and Company"
              >
                Three Lives &amp; Company
              </a>
            </li>
            <li className="border-b ">
              <a
                className="block py-2 hover:opacity-50"
                target="blank"
                href=""
                title="The Corner Bookstore"
              >
                The Corner Bookstore
              </a>
            </li>
            <li>
              <a
                className="block py-2 hover:opacity-50"
                target="blank"
                href=""
                title="McNally Jackson"
              >
                McNally Jackson
              </a>
            </li>
          </ul>
        </div> */}

        <div
          className={`${newsletterOpen ? "-translate-y-1/2  top-1/2" : "translate-y-full top-full"
            } bg-white w-full transition-all duration-500 z-50 max-w-xs fixed left-1/2 transform -translate-x-1/2 `}
        >
          <button
            onClick={() => {
              setNewsletterOpen(false);
              setModalOpen(false);
            }}
            className="w-2 h-2 p-2 m-3 absolute right-0 top-0 hover:opacity-30"
          >
            <svg width="100%" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line
                x1="1.4863"
                y1="17.5107"
                x2="18.4569"
                y2="0.540151"
                stroke="black"
                strokeWidth="0.75"
              />
              <line
                x1="1.01663"
                y1="0.540011"
                x2="17.9872"
                y2="17.5106"
                stroke="black"
                strokeWidth="0.75"
              />
            </svg>
          </button>
          <form onSubmit={handleSubmit} className="relative mx-5 my-2 mb-6">
            <h2 className="text-xs font-sans tracking-widest pt-2">Newsletter</h2>
            <br></br>
            <br></br>
            <input
              onChange={handleEmailChange}
              className="font-sans text-xs tracking-wider text-base  w-full border-b"
              type="email"
              placeholder="E-mail address"
              required
            />
            <button
              className="absolute right-0 bottom-0 text-black text-xs font-sans tracking-widest"
              type="submit"
            >
              OK
            </button>
          </form>
          {formState && (
            <div
              className="mx-5 mb-6 font-sans text-xs tracking-wider"
              dangerouslySetInnerHTML={{ __html: formState.msg }}
            />
          )}
          {!formState && (
            <p className="text-xs font-sans tracking-widest leading-normal mx-5 my-6">
              Notify me for more journal entries, news and updates via email.
            </p>
          )}
        </div>

        {modalOpen && (
          <div
            style={{ mixBlendMode: "multiply" }}
            onClick={() => {
              setModalOpen(false);
              setNewsletterOpen(false);
              setStockistOpen(false);
            }}
            className="fixed top-0 left-0 w-screen h-screen transparent z-40"
          ></div>
        )}
        <InViewWrapper setTheme={setTheme} theme={"home"} className="h-screen">
          <div data-theme="home" className=" relative">
            <InPraiseOfThought />
            <AnchorLink
              to="/#fiftenbooks"
              title="Book"
              className={`${theme === "books" && "active"} outline-none`}
            >
              <button
                style={{ animationDuration: "2s" }}
                id="DownArrow"
                className="w-10 h-10 animate-bounce mb-28 md:mb-4 block absolute left-1/2 -ml-5 outline-none bottom-0 transform -translate-x-1/2"
              >
                <svg
                  width="100%"
                  viewBox="0 0 155 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.344257"
                    y1="0.343487"
                    x2="77.2311"
                    y2="77.2304"
                    stroke="white"
                    strokeWidth="2.5"
                  />
                  <line
                    x1="154.118"
                    y1="0.69704"
                    x2="77.2308"
                    y2="77.5839"
                    stroke="white"
                    strokeWidth="2.5"
                  />
                </svg>
              </button>
            </AnchorLink>
          </div>
        </InViewWrapper>
        <div id="fiftenbooks" />
        <InViewWrapper setTheme={setTheme} theme={"home"} className="h-screen">
          <div data-theme="olive">
            <FifteenPoems />
          </div>
        </InViewWrapper>

        <InViewWrapper setTheme={setTheme} theme={"home"} className="h-screen">
          <div data-theme="dark">
            <AboutIdentity />
          </div>
        </InViewWrapper>

        <div id="books" />

        <InViewWrapper setTheme={setTheme} theme={"books"}>
          <button onClick={prevSlide} className="hidden md:block absolute z-50 outline-none focus:outline-none cursor-pointer md:w-20 md:h-20 w-12 h-12 left-0 top-1/2 transform -translate-y-1/2" aria-role="previous"><LeftArrow /></button>
          <button onClick={nextSlide} className="hidden md:block absolute z-50 outline-none focus:outline-none cursor-pointer md:w-20 md:h-20 w-12 h-12 right-0 top-1/2 transform -translate-y-1/2" aria-role="next"><RightArrow /></button>
          <div className="w-full h-full flex fader pointer-events-none" {...handlers} ref={refPassthrough}>
            <div
              data-theme="books"
              onMouseMove={(e) => mouseMove(e)}
              ref={sliderRef}
              className="w-full h-full flex fader pointer-events-none md:pointer-events-auto"
            >

              <div ref={ref} className="book-container h-full w-full relative fade-content">
                {book &&
                  book[0]._rawSpreads &&
                  book[0]._rawSpreads.map((spread, index) => (
                    <div
                      onClick={(e) => navigate(e)}
                      style={{ opacity: opacities[index] }}
                      key={`book-${index}`}
                      className={`book-image w-full h-full absolute top-0 left-0 fader__slide`}
                    >
                      <Figure node={spread} alt={`Book spread ${index}`} />
                    </div>
                  ))}
              </div>

            </div>

          </div>
          {slider && (
            <div className="dots mb-28 md:mb-8 absolute bottom-0 w-full flex justify-between md:justify-center z-50">
              <button onClick={prevSlide} className="md:hidden z-50 outline-none focus:outline-none cursor-pointer w-20 h-12 left-0 top-0 transform -translate-y-1/2" role="previous"><LeftArrow /></button>
              {[...Array(slider.details().size).keys()].map((idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      slider.moveToSlideRelative(idx);
                    }}
                    className={
                      "w-1 md:w-2 h-1 md:h-2 bg-opacity-20 bg-black rounded-full mx-1  dot" +
                      (currentSlide === idx ? " active bg-opacity-100" : "")
                    }
                  />
                );
              })}
              <button onClick={nextSlide} className="md:hidden z-50 outline-none focus:outline-none cursor-pointer w-20 h-12 right-0 top-0 transform -translate-y-1/2" role="next"><RightArrow /></button>
            </div>
          )}
        </InViewWrapper>

        <InViewWrapper mobile={true} setTheme={setTheme} theme={"books"}>
          <div
            data-theme="books"
            id="booksText"
            className="w-full h-full flex flex-col items-center justify-center"
          >
            <div className="flex flex-col m-auto mx-auto justify-center items-center fade-content relative pt-20 md:py-24 2xl:w-9/12">
              <div className="flex justify-center items-center md:w-1/2">
                <div className="mx-6 md:my-auto rte md:m-auto pb-24 md:pb-0">
                  <h2 className="text-center mb-8">In Praise of Thought</h2>
                  {book && book[0]._rawDescription && (
                    <PortableText blocks={book[0]._rawDescription} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </InViewWrapper>

        {/* <InViewWrapper setTheme={setTheme} theme={'books'}>
          <div data-theme="books" id="booksVideo" className='w-full h-full flex flex-col items-center justify-center'>
            <div className='flex flex-col m-auto mx-auto justify-center items-center fade-content relative py-24 2xl:w-9/12'>
              <div className='mx-auto relative cursor-pointer md:w-1/2'>
                <div className="m-6 relative">
                  <img className={`${videoShow && 'hidden'} absolute z-10`} onClick={() => { setVideoShow(true); playVideo(); }} src="/my_fantasy_my_privacy_placeholder.jpg" alt="my fantasy my privacy" />
                  <video ref={videoRef} placeholder='/my_fantasy_my_privacy_placeholder.jpg' className={`${!videoShow ? 'opacity-0' : ''}`} controls>
                    <source src='/my_fantasy_my_privacy.mp4' type='video/mp4' />
                  </video>
                  <button onClick={() => { setVideoShow(true); playVideo(); }} className="pr-2 w-full flex items-end" type="button">
                    <svg className="w-3 h-3 ml-0 relative" width="20" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M39 22.5L0.749998 44.5836L0.75 0.41635L39 22.5Z" fill="black" />
                    </svg>
                    <p className="text-xs tracking-widest antialiased pt-2 relative ml-2" style={{ top: "2px" }}>Recital&mdash;"In Praise Thought", 1:24</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </InViewWrapper> */}

        <div id="about" />

        <InViewWrapper mobile={true} setTheme={setTheme} theme={"about"}>
          {/* <h2 className='mb-4'>About</h2> */}
          {/* <div className='text-bg pt-16 lg:pt-12 w-full min-h-screen block px-6 text-sm md:text-lg leading-tight lg:text-4xl xxl:text-5xl lg:leading-tighter  fade-content'>
            {about && <PortableText blocks={about[0]._rawDescription} />}
          </div> */}

          <div data-theme="about" className="relative overflow-hidden">
            {/* {theme === "about" && <div style={{ background: "rgb(174,35,49)", background: "linear-gradient(0deg, rgba(174,35,49,0) 0%, rgba(174,35,49,1) 58%)" }} className="fixed top-0 h-32 w-full z-0"></div>} */}

            <div className="min-h-screen flex flex-col-reverse md:flex-row text-xs md:text-sm">
              <div className="flex-1 flex justify-center items-center">
                {about && (
                  <div className="mx-auto my-auto py-24 md:py-0 text-white md:px-6 max-w-3xl rte">
                    <PortableText blocks={about[0]._rawDescription} />
                  </div>
                )}
              </div>
              {about && about[0] && (
                <div className="flex-1 relative hidden md:block">
                  <Figure node={about[0]._rawImage} alt="Elizabeth Haddad" />
                </div>
              )}
            </div>
            {/* <h2 className='mb-4'>About</h2> */}
            {/* <div className='text-bg pt-16 lg:pt-12 w-full min-h-screen block px-6 text-sm md:text-lg leading-tight lg:text-4xl xxl:text-5xl lg:leading-tighter  fade-content'>
            {about && <PortableText blocks={about[0]._rawDescription} />}
          </div> */}
          </div>
        </InViewWrapper>

        <InViewWrapper className={"md:hidden"} setTheme={setTheme} theme={"about"}>
          <div data-theme="about" className="relative md:hidden">
            <div className="min-h-screen flex flex-col-reverse md:flex-row text-xs md:text-sm">
              {about && about[0] && (
                <div className="flex-1 relative">
                  <Figure node={about[0]._rawImage} />
                </div>
              )}
            </div>
            {/* <h2 className='mb-4'>About</h2> */}
            {/* <div className='text-bg pt-16 lg:pt-12 w-full min-h-screen block px-6 text-sm md:text-lg leading-tight lg:text-4xl xxl:text-5xl lg:leading-tighter  fade-content'>
            {about && <PortableText blocks={about[0]._rawDescription} />}
          </div> */}
          </div>
        </InViewWrapper>

        <div id="journal" />

        <InViewWrapper setTheme={setTheme} theme={"journal"}>
          <div className="w-full min-h-screen flex overflow-hidden">
            <div data-theme="journal" className="w-full relative transition-all duration-150">
              {children}
            </div>
          </div>
        </InViewWrapper>

        <InViewWrapper className="overflow-visible" setTheme={setTheme} theme={"contact"}>
          <div data-theme="contact" className="w-full min-h-screen flex">
            <Contact
              setModalOpen={setModalOpen}
              setStockistOpen={setStockistOpen}
              setNewsletterOpen={setNewsletterOpen}
            />
          </div>
        </InViewWrapper>
      </div>

      <div
        data-theme={theme}
        style={{ bottom: "1.5rem" }}
        className="cta sticky left-0 bottom-0 m-6 mb-6 bg-transparent z-40"
      >
        <button
          aria-label="Subscribe to journal"
          className="button select-none"
          onClick={() => {
            setModalOpen(true);
            setNewsletterOpen(true);
            setStockistOpen(false);
          }}
        >
          Subscribe
        </button>
        {/* <button aria-label="Purchase Book" className='button ml-6 select-none' onClick={() => { setModalOpen(true); setNewsletterOpen(false); setStockistOpen(true); }}>Purchase Book</button> */}
        <a
          className="button ml-6 select-none"
          href="https://www.barnesandnoble.com/w/in-praise-of-thought-elizabeth-haddad/1140074320;jsessionid=6A0571FB62F43BB8BC74AD0B7C040747.prodny_store02-atgap16?ean=9781736154014"
          title="Purchase Book"
          target="_blank"
        >
          Purchase Book
        </a>
      </div>

      <div style={{ fontSize: ".65rem" }} className="tracking-widest p-6 pt-0 block md:hidden">
        Design: BRDAR
      </div>

      <div id="contact" />
    </>
  );
};

export default Layout;
