import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';

const InViewWrapper = ({ children, setTheme, theme, className, mobile = false, stacking = true }) => {
  const [viewedOnce, setViewedOnce] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: [.5, .85, 1],
    root: null,
    rootMargin: '0px',
    // initialInView: 'home'
  });

  useEffect(() => {
    //   console.log('')
    // console.log(theme + ': ' + inView);
    if (inView) {
      setTheme(theme);
      setViewedOnce(true);
    }

  })

  return (
    <div data-wrapper={theme} className={`${viewedOnce && 'in-view'} ${className} ${stacking && 'stacking-slide relative'} ${mobile && 'stacking-slide-mobile'} z-10`} ref={ref}>
      {children}
    </div >
  );
}

export default InViewWrapper;