import React, { useState, useRef, useEffect } from "react";
import ReactVivus from "react-vivus";
import fifteen from "../../static/fifteen.svg";
import fifteenMobile from "../../static/fifteen-mobile.svg";
import { useViewportScroll, motion, useTransform, useMotionValue } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FifteenPoems = (props) => {
  const [word1Width, setWord1Width] = useState();
  const [word2Width, setWord2Width] = useState();
  const [done1, setDone1] = useState(false);
  const [done2, setDone2] = useState(false);
  const { scrollYProgress } = useViewportScroll();

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  });

  return (
    <div
      data-theme="olive"
      className="relative grid grid-cols-2 w-full min-h-screen fade-content svg-anim"
      style={{ transform: "translateX(3%)" }}
    >
      <ReactVivus
        id="FifteenSVG"
        option={{
          file: fifteen,
          animTimingFunction: "EASE_OUT",
          pathTimingFunction: "EASE_OUT",
          type: "scenario-sync",
          reverseStack: true,
          duration: 10,
        }}
        callback={() => setDone1(true)}
        className={`${
          done1 && "finished"
        } hidden md:block flex-1 left-0 relative w-screen h-screen z-10`}
      />
      <ReactVivus
        id="FifteenSVGMobile"
        option={{
          file: fifteenMobile,
          animTimingFunction: "EASE_OUT",
          pathTimingFunction: "EASE_OUT",
          type: "scenario-sync",
          reverseStack: true,
          duration: 10,
        }}
        callback={() => setDone2(true)}
        className={`${done2 && "finished"} md:hidden flex-1 left-0 relative w-screen h-screen z-10`}
      />
    </div>
  );
};

export default FifteenPoems;
