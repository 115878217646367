import React from 'react'
// import { HaddadBooksLogo } from './svg';
import HaddadBooksLogo from './haddad-books';

const Contact = ({ setModalOpen, setNewsletterOpen, setStockistOpen }) => {
    return (
        <>
            <p className=" z-10 text-xs font-sans absolute left-0 bottom-0 tracking-widest mt-24 mx-6">
                <span className="text-white">General enquiries:<br /><a href="mailto:writer@elizabethhaddad.com">writer@elizabethhaddad.com</a></span>
            </p>

            <div className="relative flex flex-col w-full pointer-events-none fade-content">
                {/* Spacer cuz margin does not work on below logo for some buggy reason */}
                <div id="HaddadBooksLogo" className='w-full h-full relative'>
                    <div className="h-20 lg:h-24 w-full block" />
                    <HaddadBooksLogo />
                </div>

                <footer className={`absolute bottom-0 left-0 w-full mb-20 md:mb-2 font-sans text-sm`}>
                    <div className={`flex md:justify-end mx-auto px-6 pb-2  text-xs tracking-widest`}>
                        <div className='absolute w-40 sm:mb-2 left-1/2 transform -translate-x-1/2 bottom-0'>
                        </div>
                    </div>
                </footer>
            </div>

            <div style={{ fontSize: ".65em" }} className="absolute bottom-0 tracking-widest m-6 right-0 -mb-14 pb-1 hidden md:block">Design: BRDAR</div>

        </>
    );
}

export default Contact;