import { Link } from 'gatsby'
import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import useScrollDirection from './useScrollDirection';

const Header = ({ siteTitle, theme }) => {

  // const scrollDir = useScrollDirection('up');

  return (
    <header data-theme={`header-${theme}`} className={`font-sans text-xs transform transition-all fixed left-0 w-full z-50`}>
      <div className='px-6 inline-block sm:flex justify-between mx-auto'>
        <AnchorLink
          to="/#home"

          title={siteTitle}
          className={`${theme === 'home' && 'active '} site-title tracking-widest inline-block py-2 my-2 sm:m-0`}
          stripHash
        />
        <br id="HeaderBR" className="sm:hidden" />
        <AnchorLink
          to="/#books"
          title="Book"
          className={`${theme === 'books' && 'active '} tracking-widest py-2 mr-6 sm:mr-0`}
          stripHash
        />
        <AnchorLink
          to="/#about"
          title="About"
          className={`${theme === 'about' && 'active '} tracking-widest py-2 mr-6 sm:mr-0`}
          stripHash
        />
        <AnchorLink
          to="/#journal"
          title="Journal"
          className={`${theme === 'journal' && 'active '} tracking-widest py-2 mr-6 sm:mr-0`}
          stripHash
        />
        <AnchorLink
          to="/#contact"
          title="Contact"
          className={`${theme === 'contact' && 'active '} tracking-widest py-2 mr-6 sm:mr-0`}
          stripHash
        />
      </div>
    </header>
  )
}

export default Header
