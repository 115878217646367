import React, { useState, useRef, useEffect } from "react";
import ReactVivus from "react-vivus";
import AboutIdentitySVG from "../../static/aboutIdentity.svg";
import AboutIdentitySVGMobile from "../../static/aboutIdentity-mobile.svg";
import { motion, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutIdentity = (props) => {
  const [word1Width, setWord1Width] = useState();
  const [word2Width, setWord2Width] = useState();
  const [done1, setDone1] = useState(false);
  const [done2, setDone2] = useState(false);
  const { scroll } = props;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  });

  return (
    <div className="relative w-full min-h-screen fade-content">
      <ReactVivus
        id="AboutIdentitySVG"
        option={{
          file: AboutIdentitySVG,
          animTimingFunction: "EASE_OUT",
          pathTimingFunction: "EASE_OUT",
          type: "scenario-sync",
          duration: 10,
          reverseStack: true,
        }}
        callback={() => setDone1(true)}
        className={`${
          done1 && "finished"
        } hidden md:block mb-auto flex-1 svg-anim left-0 relative w-screen h-screen z-10`}
      />
      <ReactVivus
        id="AboutIdentitySVGMobile"
        option={{
          file: AboutIdentitySVGMobile,
          animTimingFunction: "EASE_OUT",
          pathTimingFunction: "EASE_OUT",
          type: "scenario-sync",
          duration: 10,
          reverseStack: true,
        }}
        callback={() => setDone2(true)}
        className={`${
          done2 && "finished"
        } md:hidden mb-auto flex-1 svg-anim left-0 relative w-screen h-screen z-10`}
      />
    </div>
  );
};

export default AboutIdentity;
