import React from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import clientConfig from '../../client-config'

export default ({ node, alt }) => {
  if (!node || !node.asset || !node.asset._id) { return null }
  // const fluidProps = getFluidGatsbyImage(
  //   node.asset._id,
  //   { maxWidth: 675 },
  //   clientConfig.sanity
  // )
  const imageData = getGatsbyImageData(node.asset._id, { maxWidth: 700 }, clientConfig.sanity)
  // console.log(imageData)
  return (
    <figure>
      {/* <GatsbyImage image={imageData} alt={'book'} /> */}
      <img className='absolute h-full w-full top-0 left-0 object-contain lg:object-cover object-center' alt={alt} srcSet={imageData.images.fallback.srcSet} src={imageData.images.fallback.src} />
      <figcaption>{node.caption}</figcaption>
    </figure>
  )
}
