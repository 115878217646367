import React, { useState, useRef, useEffect } from "react";
import ReactVivus from "react-vivus";
import InPraiseOfThoughtSVG from "../../static/inPraiseOfThought.svg";
import InPraiseOfThoughtSVGMobile from "../../static/inPraiseOfThought-mobile.svg";
import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue,
  useElementScroll,
} from "framer-motion";
import { useInView } from "react-intersection-observer";

const ParallaxModule = ({ src, scroll, influence, className, children, ...style }) => {
  const [elementTop, setElementTop] = useState(0);
  const ref = useRef(null);
  const { scrollYProgress } = useElementScroll(ref);
  const y1 = useTransform(scrollYProgress, [0, 300], [0, 200]);

  const y = useTransform(scrollYProgress, [elementTop, elementTop + influence], [0, -1], {
    clamp: false,
  });

  useEffect(() => {
    const element = ref.current;
    setElementTop(element.offsetTop);
  }, [ref]);

  return (
    <div ref={ref} className={`${className}`}>
      <motion.div style={{ ...style, y1 }}>{children}</motion.div>
    </div>
  );
};

const InPraiseOfThought = (props) => {
  const [word1Width, setWord1Width] = useState();
  const [word2Width, setWord2Width] = useState();
  const [done1, setDone1] = useState(false);
  const [done2, setDone2] = useState(false);
  const { scroll } = props;

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  });

  // useLayoutEffect(() => {
  //   setWord1Width(window.innerWidth / 0.95);
  //   setWord2Width(window.innerWidth / 1.15);
  // });

  return (
    <div className="relative w-full min-h-screen fade-content">
      <div style={{ transform: "scale(0.935)" }}>
        <ReactVivus
          id="InWord"
          option={{
            file: InPraiseOfThoughtSVG,
            animTimingFunction: "EASE_OUT",
            pathTimingFunction: "EASE_OUT",
            type: "scenario-sync",
            // duration: 10,
            reverseStack: true,
          }}
          // duration={1000}
          // style={{ width: word2Width }}

          callback={() => {
            setDone1(true);
            console.log("done");
          }}
          className={`${
            done1 && "finished"
          } hidden md:block mb-auto flex-1 svg-anim left-0  h-screen z-20`}
        />
        <ReactVivus
          id="InPraiseWord"
          option={{
            file: InPraiseOfThoughtSVGMobile,
            animTimingFunction: "EASE_OUT",
            pathTimingFunction: "EASE_OUT",
            type: "scenario-sync",
            duration: 10,
            reverseStack: true,
          }}
          callback={() => setDone2(true)}
          className={`${
            done2 && "finished"
          } block md:hidden mb-auto flex-1 svg-anim left-0 h-screen z-20`}
        />
      </div>
    </div>
  );
};

export default InPraiseOfThought;
